import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Box, Heading, Paragraph, Text } from 'grommet'
import { Next, Previous } from 'grommet-icons'
import styled from 'styled-components'

import { Layout } from '../layout'
// import Dump from '../components/Dump'

const Content = styled.article`
  max-width: 720px;
  margin: 0 auto;
`
const Navigation = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-template-areas: 'left right';
  padding-top: 12px;
  margin-top: 12px;
  box-shadow: 0 -2px 1px -1px rgba(0, 0, 0, 0.5);
  .go-right {
    grid-area: right;
  }
`

const PostTemplate = ({ data, pageContext }) => {
  const { frontmatter, body } = data.mdx
  const { previous, next } = pageContext
  return (
    <Layout>
      <Content>
        {!!frontmatter.cover && <Img fluid={frontmatter.cover.childImageSharp.fluid} />}
        <Heading level={2} margin={{ top: 'small' }}>
          {frontmatter.title}
        </Heading>
        <Paragraph>{frontmatter.date}</Paragraph>
        <MDXRenderer>{body}</MDXRenderer>
        <hr />
        <Navigation>
          {!!previous && (
            <Link to={previous.fields.slug} className="go-left">
              <Box align="start">
                <Previous size="small" /> <Text size="small">{previous.frontmatter.title}</Text>
              </Box>
            </Link>
          )}
          {!!next && (
            <Link to={next.fields.slug} className="go-right">
              <Box align="end">
                <Next size="small" />
                <Text size="small" textAlign="end">{next.frontmatter.title}</Text>
              </Box>
            </Link>
          )}
        </Navigation>
      </Content>
    </Layout>
  )
}
export default PostTemplate

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        cover {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 380) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}
